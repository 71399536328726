import React from 'react'
import * as Sentry from '@sentry/node'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { ToastContainer } from 'react-toastify'
import Head from 'next/head'

import { Header, PageWrapper } from 'components'

import AppProviders from 'context'

import Theme, { GlobalStyle } from 'theme'
import 'react-toastify/dist/ReactToastify.css'
import '../src/routes/Campaigns/show/components/Gallery/gallery.css'
import '../src/styles/_app.css'

import { version, name } from '../package.json'

if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_URL,
    environment: process.env.NEXT_PUBLIC_NODE_ENV,
    debug: process.env.NEXT_PUBLIC_NODE_ENV !== 'production',
    release: `${name}@${version}`
  })
}

const App = ({ Component, pageProps }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
    <Head>
      <script async src='https://www.googletagmanager.com/gtag/js?id=UA-91302561-1' />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-91302561-1', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-55G2RXJ');`
        }}
      />
    </Head>
    <AppProviders>
      <Theme>
        <GlobalStyle />

        <Header />
        <ToastContainer hideProgressBar pauseOnFocusLoss={false} autoClose={3000} />
        <Component {...pageProps} />
      </Theme>
    </AppProviders>
  </MuiPickersUtilsProvider>
)

export default App
